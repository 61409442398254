import * as S from "../Styles";
import first from "../../../assets/imgs/snow/first.png";
import second from "../../../assets/imgs/snow/second.png";
import third from "../../../assets/imgs/snow/3.png";

const items = [
  {
    img: first,
    content:
      "Открывайте праздничные Новогодние кейсы с ярким голубым ценником, чтобы выбить Бравл Снежинки"
  },
  {
    img: second,
    content:
      "Пополняйте свой баланс в разделе ивента, чтобы удвоить свои шансы на редкую Бравл Снежинку."
  },
  {
    img: third,
    content:
      "Собрав 20 Бравл Снежинок вы сможете получить золотого Леона, а собрав 25 Бравл Снежинок - 10.000₽ на свою карту!"
  }
];
export const First = () => {
  return (
    <S.Content>
      <S.Heading>Готовы к захватывающему Новогоднему приключению?</S.Heading>
      <S.SubHeading>
        Не упустите возможность окунуться в атмосферу магии и удачи!
      </S.SubHeading>
      <S.FirstDivider>
        {items.map(({ img, content }, i) => (
          <S.Item key={i}>
            <S.ImgContainer>
              <img src={img} alt={"Бонусы в честь нового года"} />
            </S.ImgContainer>
            <S.ItemText>{content}</S.ItemText>
          </S.Item>
        ))}
      </S.FirstDivider>
    </S.Content>
  );
};
