import * as S from "./Styles";
import rightPers from "../../../../assets/imgs/snow/right-img.png";
import leftPers from "../../../../assets/imgs/snow/left-img.png";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../../../../graphql/Query";
import { GetMeQuery } from "../../../../gql/graphql";
import snow from "../../../../assets/imgs/snow/snow-img.png";

type FooterProps = {
  me?: GetMeQuery;
  isEventPage?: boolean;
};
export const Footer = ({ isEventPage, me }: FooterProps) => {
  const { data } = useQuery(IS_LOGGED_IN);

  return (
    <S.Wrapper $isEventPage={isEventPage}>
      {!isEventPage && (
        <S.Relative>
          <S.Button to={"/event"}>
            <img src={snow} alt={"Снег"} />
            Страница ивента
          </S.Button>
        </S.Relative>
      )}
      {/*<S.RightPerson*/}
      {/*  src={rightPers}*/}
      {/*  alt={"Right Person"}*/}
      {/*  $isEventPage={isEventPage}*/}
      {/*/>*/}
      {/*<S.LeftPerson*/}
      {/*  src={leftPers}*/}
      {/*  alt={"Left Person"}*/}
      {/*  $isEventPage={isEventPage}*/}
      {/*/>*/}
    </S.Wrapper>
  );
};
