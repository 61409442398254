import { Cases } from "./Cases/Cases";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { useExternalLink } from "./useExternalLink";
import { Helmet } from "react-helmet-async";
import { useLayoutEffect } from "react";
import { Banner } from "./Banner/Banner";
import { SnowBanner } from "./SnowBanner/Banner";

export const Main = () => {
  useExternalLink();
  useLayoutEffect(() => {
    document.title = "Brawl Cases | Открывай кейсы brawl stars";
  }, []);
  useReloadScrollTop();
  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>Brawl Cases | Открывай кейсы brawl stars</title>
        <meta
          name="description"
          content="Самый лучший и надежный сайт по открытию кейсов Brawl Stars! Большие бонусы, высокие шансы и надежность! Открой кейс brawl stars уже сейчас!"
        />
        <link rel="canonical" href="https://brawlcases.com" />
        <meta
          property="og:title"
          content="Покупай гемы и открывай кейсы бравл старс на сайте brawlcases.com! Легко и быстро пополняйте баланс Brawl Stars!"
        />
      </Helmet>
      {/*<Banner />*/}
      {/*<HellBanner />*/}
      <SnowBanner />
      <Cases />
    </div>
  );
};
