import * as S from "./Styles";
import { ReactComponent as Text } from "../../../../assets/icons/snow/text.svg";
// type HeadProps = {};
export const Head = () => {
  return (
    <S.Wrapper>
      <Text />
    </S.Wrapper>
  );
};
