import * as S from "../Footer/Styles";
import { useToggleVisible } from "../../../../hooks/useToggleVisible";
import { QuestionModal } from "./QuestionModal/QuestionModal";
import { ReactComponent as Snow } from "../../../../assets/icons/snow/single-snow.svg";
import snow from "../../../../assets/imgs/snow/snow-img.png";

type SpecialBlockProps = {};
export const SpecialBlock = ({}: SpecialBlockProps) => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);

  const onClick = () => {
    toggle();
  };
  return (
    <>
      <S.TopDivider>
        <S.Question onClick={onClick}>
          <div>?</div>
        </S.Question>
      </S.TopDivider>
      <S.BottomDivider>
        <S.Relative>
          <S.Button to={"/event"}>
            <img src={snow} alt={"Снег"} />
            <Snow /> event
          </S.Button>
        </S.Relative>
      </S.BottomDivider>
      <QuestionModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
    </>
  );
};
