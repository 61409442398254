import * as S from "./Styles";
import { Head } from "./Head/Head";
import { Body } from "./Body/Body";
import { Footer } from "./Footer/Footer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { SpecialBlock } from "./SpecialBlock/SpecialBlock";
import { useQuery } from "@apollo/client";
import { GET_ME } from "../../../graphql/Query";

type BannerProps = {
  isEventPage?: boolean;
};
export const SnowBanner = ({ isEventPage }: BannerProps) => {
  const { data: me } = useQuery(GET_ME, {
    fetchPolicy: "cache-first"
  });
  const width = useWindowWidth();
  return width < 576 && !isEventPage ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "2rem",
        marginBottom: "-2rem"
      }}
    >
      <div style={{ maxWidth: "280px", margin: "auto" }}>
        <SpecialBlock />
      </div>
    </div>
  ) : (
    <S.Wrapper $isEventPage={isEventPage}>
      <Head />
      <Body />
      <Footer isEventPage={isEventPage} me={me} />
    </S.Wrapper>
  );
};
