import styled, { css } from "styled-components";
import { getTransition } from "../../../../Styles/utilites";
import { Link } from "react-router-dom";

type WrapperProps = {
  $isSpecial?: boolean;
};
export const Wrapper = styled.section<WrapperProps>`
  text-align: center;
  padding: 5rem 1rem 0;
  display: flex;
  flex-direction: column;
  ${({ $isSpecial }) =>
    $isSpecial &&
    css`
      padding-top: 0;
    `}
`;

type HeadingProps = {
  $isSpecial?: boolean;
};
export const Heading = styled.h4<HeadingProps>`
  font-weight: 500;
  margin: 0 auto 1rem;
  ${({ $isSpecial }) =>
    $isSpecial &&
    css`
      color: #0085ff;
    `}
`;

export const CasesContainer = styled.ul`
  margin: auto;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

type CaseEltProps = {
  $isSolo?: boolean;
  $isSpecial?: boolean;
};
export const CaseElt = styled(Link)<CaseEltProps>`
  text-decoration: none;
  cursor: pointer;
  margin-top: 1em;
  border-radius: 1em;
  padding-bottom: 1em;
  background-color: transparent;
  transform: translateY(10px);
  ${({ $isSolo }) =>
    !$isSolo &&
    css`
      width: 50%;
      flex: 0 1 40%;
    `}
  img {
    // height: 15rem;
    height: auto;
    width: auto;
    max-width: 40vw;
    transform: translateY(-20px);
    ${getTransition(300, "transform", "ease-in-out")}
  }
  > div {
    color: ${({ theme }) => theme.colors.grey85};
    transform: translateY(-20px);
    ${getTransition(300, ["color", "transform"], "ease-in-out")}
  }
  > button {
    ${getTransition(300, ["transform"], "ease-in-out")}
    transform: translateY(-20px);
  }
  ${getTransition(300, ["background-color", "transform"], "ease-in-out")}
  &:hover {
    transform: translateY(-10px);
    background-color: hsla(
      ${({ theme, $isSpecial }) =>
        $isSpecial
          ? theme.colors.brightBlueValue
          : theme.colors.brightPurpleValue},
      0.2
    );
    > div {
      color: ${({ theme }) => theme.colors.font};
      transform: translateY(0);
    }
    > button {
      transform: translateY(0);
    }
    img {
      transform: scale(1.07) translateY(0);
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    width: initial;
    flex: initial;
    img {
      height: 12rem;
    }
  }
`;

export const Name = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 400px) {
    line-height: 0;
  }
`;
