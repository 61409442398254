import styled from "styled-components";
import bg from "../../../assets/imgs/snow/bg.png";
import bgSnow from "../../../assets/imgs/snow/snow-running.png";

type WrapperProps = {
  $isEventPage?: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  display: ${({ $isEventPage }) => ($isEventPage ? "flex" : "none")};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 2rem;
  background-image: url(${() => bg});
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: color;
  height: auto;
  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${() => bgSnow});
    background: url(${() => bgSnow}), lightgray 50% / cover no-repeat;
    mix-blend-mode: plus-lighter;
    background-size: cover;
    background-position: center;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    display: flex;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 1rem;
  }
`;
