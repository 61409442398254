import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.lg} {
    //margin: 1.2rem 0 1.2rem;
  }
`;

export const Container = styled.div`
  text-align: center;
  margin: 0rem 0 0rem;
  color: #fff;
  font-size: 0.85rem;
  max-width: 420px;
`;

export const TimerDivider = styled.div`
  position: relative;
  //min-height: 10.17rem;
  margin-top: 2rem;
  @media screen and ${({ theme }) => theme.media.xl} {
    // min-height: 3.18rem;
    margin-top: 0;
  }
`;

export const Description = styled.div`
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 600;
`;
